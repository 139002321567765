import React, { useState, useEffect, useRef } from 'react';
import './css/base.css'


function Header() {

    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = () => {
      setDrawerOpen(!isDrawerOpen);
    };

  return (
    <>

      <div className="navbar" name="main-top-nav">
        <div className="hamburger" onClick={toggleDrawer}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="logo">
          <a href='/'>
            <img src="./img/Z_AYTCHELL.png" alt="Logo" />
          </a>
        </div>
        {/* Hamburger menu icon */}

        {/* Navigation links */}
        <ul className={`nav-links ${isDrawerOpen ? 'open' : ''}`}>
          <li><a href="https://shop.aytchell.com/pages/about">ABOUT</a></li>
          <li><a href="https://shop.aytchell.com">SHOP</a></li>
          <li><a href="https://shop.aytchell.com/pages/contact">CONTACT</a></li>
        </ul>
      </div>
      </>
  );
}

export default Header;
