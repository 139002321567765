import React, { useState, useEffect, useRef } from 'react';
import './css/base.css'


function LandingPage() {

  function scrollToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return (
    <>

      <button onClick={scrollToTop} id="backToTop" title="Go to top">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5" />
        </svg>
      </button>
      <main>
        <div id="hero-wrapper">
          <img src="./img/hero/milan-bg.jpg" alt="Portrait" className='bg-image' />
          <div className="hero-overlay">
            <h3 className='text-center'>#MFW24'</h3>
            <a href="/mfw" className='hover-target'>
              <img src="./img/hero/middle.jpg" alt="Portrait" className='' />
            </a>
            <span className='overlay-image-caption'>FASHION SHOW</span>
            {/* <img src="./img/hero/hero-front-main.jpg" alt="Portrait" className='overlayed-item1' /> */}
          </div>
        </div>
        {/* <div id="main-gal-wrapper-replacer ">
          <div className="caption">
            <p>Explore the refined craftsmanship of AYTCHELL's iconic bags.</p>
            <a href='https://shop.aytchell.com' className='mt-4 shop-now-action'>SHOP NOW</a>
          </div>
        </div> */}
        <div className="gallery-wrap gallery-wrap--dense d-none d-sm-none d-md-block" style={{ height: '100vh' }} id='main-gal-wrapper'>
          <div className="gallery gallery--stack gallery--stack-inverse gallery--stack-dark" id="gallery-4">
            <a href="https://aytchell.com" className="gallery__item" style={{ backgroundImage: `url('./img/mini-sweethearts/metallic.jpg')` }}></a>
            <div className="gallery__item" style={{ backgroundImage: `url('./img/mini-sweethearts/nude.jpg')` }}></div>
            <div className="gallery__item" style={{ backgroundImage: `url('./img/mini-sweethearts/blue.jpg')` }}></div>
            <div className="gallery__item" style={{ backgroundImage: `url('./img/mini-sweethearts/white.jpg')` }}></div>
            <div className="gallery__item" style={{ backgroundImage: `url('./img/mini-sweethearts/olive-green.jpg')` }}></div>
            <div className="gallery__item" style={{ backgroundImage: `url('./img/mini-sweethearts/black.jpg')` }}></div>
            <div className="caption">
              <p>Explore the refined craftsmanship of AYTCHELL's iconic bags.</p>
              <a href='https://shop.aytchell.com' className='mt-4 shop-now-action'>SHOP NOW</a>
            </div>
          </div>
        </div>
        <div id="third-sec">
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-6 col-12'>
                <div className='life-left-wrapper'>
                  <a href="https://shop.aytchell.com" className='hover-target'>
                    <img className="img-fluid " src="./img/life/right-left.jpg" alt="Life Left" />
                    <div className='overlay'>
                      <h3>BESTSELLER</h3>
                      <span>DISCOVER</span>
                    </div>
                  </a>
                </div>
              </div>
              <div className='col-md-6 col-12'>
                <div className='life-right-wrapper hover-target' >
                  <a href="https://shop.aytchell.com"  className='hover-target'>
                    <img className="img-fluid " src="./img/life/right-right.jpg" alt="Life Right" />
                    <div className='overlay'>
                      <h3>SWEETHEART</h3>
                      <span>DISCOVER</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='life-bottom-wrapper'>
                  <a href="/mfw" className='hover-target'> 
                    <img className="img-fluid" src="./img/life/full-screen.jpg" alt="Life Middle" />
                    <div className='overlay'>
                      <h3>#MFW24'</h3>
                      <span>DISCOVER</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="d-table-row">
        <div className="d-table-cell col-md-6 align-middle">
          <img className="img-fluid left-image" src="./img/life/left-left.jpg" alt="Left" />
        </div>
        <div className="d-table-cell col-md-6">
          <div className="d-flex flex-column h-100">
            <div className="row g-2 flex-grow-1">
              <div className="col-4 d-flex align-items-center justify-content-center">
                <img className="img-fluid" src="./img/life/right-left.jpg" alt="Right Top 1" />
              </div>
              <div className="col-4 d-flex align-items-center justify-content-center">
                <img className="img-fluid" src="./img/life/right-middle.jpg" alt="Right Top 2" />
              </div>
              <div className="col-4 d-flex align-items-center justify-content-center">
                <img className="img-fluid" src="./img/life/right-right.jpg" alt="Right Top 3" />
              </div>
            </div>
            <div className="row flex-grow-1 mt-2">
              <div className="col-12 d-flex align-items-center justify-content-center">
                <img className="img-fluid bottom-image" src="./img/life/right-bottom.jpg" alt="Right Bottom" />
              </div>
            </div>
          </div>
        </div>
      </div>  */}
        </div>

        {/* <div style={{ height: '100vh', width: '100vw' }} id='canvas-bag-custruction-wrapper'>
          <div className="canvas-container">
            <canvas id="canvas-bag-construction" />
          </div>
        </div> */}
        {/* <div className='container' style={{ padding: '20px 0' }}>
          <h2 className='mb-5'>Instagram</h2>
          <div className='row g-4'>
            <div className='col-md-3'>
              <img className='card-img-top' variant="top" src="./img/insta/insta-order-1.jpg" />
            </div >
            <div className='col-md-3'>
              <img className='card-img-top' variant="top" src="./img/insta/insta-order-2.jpg" />
            </div >
            <div className='col-md-3'>
              <img className='card-img-top' variant="top" src="./img/insta/insta-order-3.jpg" />
            </div >
            <div className='col-md-3'>
              <img className='card-img-top' variant="top" src="./img/insta/insta-order-4.jpg" />
            </div>
          </div>
        </div> */}
      </main >

    </>
  );
}

export default LandingPage;
