import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const Slider = ({ slides }) => {
  return (
    <>
      <Swiper
        spaceBetween={10}
        slidesPerGroup={2}
        grabCursor={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
            slidesPerGroup:1
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
            slidesPerGroup:1
          },
          1024: {
            slidesPerView: 3.2,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {slides.map((slide, index) => (
           <SwiperSlide key={index}>
             <div >
               <img
                 src={slide}
                 alt={`Slide ${index + 1}`}
                 className="img-fluid"
                 style={{  objectFit: 'cover' }}
               />
             </div>
             </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default Slider;
